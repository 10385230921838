<template>
  <div class="container">
    <InvestorLogin />
    <div v-if="languageCode('es')">
      <h1>Invierte en la Dehesa</h1>
      <p class="claim">Plantemos árboles, luchemos contra la desertificación, mantengamos la biodiversidad, ganemos algo
        de dinero, y proporcionemos comida para nuestras familias - juntos.</p>
      <p>Vamos a plantar y mantener una nueva Dehesa. Estarás invirtiendo en franjas forestales que desarrollamos juntos
        en varias fases. El retorno de tu inversión provendrá de las ventas de madera, y también recibirás productos
        comestibles mientras crece la nueva Dehesa.</p>
    </div>
    <div v-else-if="languageCode('de')">
      <h1>Investieren Sie in die Dehesa</h1>
      <p class="claim">Lasst uns Bäume pflanzen, der Wüstenbildung entgegenwirken, die Artenvielfalt erhalten, etwas Geld
        verdienen und Essen für unsere Familien bereitstellen - zusammen.</p>
      <p>Wir werden eine neue Dehesa pflanzen und pflegen. Sie werden in Waldstreifen investieren, die wir gemeinsam
        in mehreren Phasen entwickeln. Die Rendite Ihrer Investition wird aus dem Holzverkauf kommen, und Sie werden auch
        essbare Produkte erhalten, während die neue Dehesa wächst.</p>
    </div>
    <div v-else>
      <h1>Invest in the Dehesa</h1>
      <p class="claim">Let's plant trees, fight desertification, maintain biodiversity, earn some money, and provide food
        for our families - together.</p>
      <p>We are going to plant and maintain a new Dehesa. You will be investing in forest strips that we develop together
        in
        several phases. The return on your investment will come from wood sales, and you will also receive edible products
        while the new Dehesa grows.</p>
    </div>

    <PurchaseTreeInvestment />

    <div v-if="languageCode('es')">
      <h2>Franjas Forestales</h2>
      <p>Basándonos en varios factores, plantaremos el nuevo bosque en varias fases y en forma de franjas, cada una de
        2000 m2
        de tamaño. Estas franjas son la unidad base para tu inversión.</p>
      <p>Cada franja pasa por varias fases en el siguiente orden:</p>
      <ul class="phases">
        <li>Aflojar mecánicamente el suelo y enriquecerlo con una mezcla de estiércol/compost.</li>
        <li>Instalar equipos de riego.</li>
        <li>Semillar Sunn Hemp (Crotalaria juncea), girasoles, y maíz para biomasa cada tres meses. Durante el invierno,
          utilizamos centeno.</li>
        <li>Cada tres meses, cortar o pastar Sunn Hemp, girasoles y maíz para añadir la biomasa al suelo.</li>
        <li>Al inicio del segundo año, plantar aproximadamente 80 árboles de Paulownia y continuar con la mezcla de
          plantas para
          biomasa.</li>
        <li>Al final del segundo año, después de cortar las plantas de biomasa, plantar árboles nativos y especies de
          arbustos para llenar el vacío
          entre los árboles de Paulownia. Realizar un corte técnico en los árboles de Paulownia para un mejor crecimiento.
          Convertir los restos
          de poda en astillas de madera y aplicarlas alrededor de la base de cada árbol.</li>
        <li>En el tercer año, inspeccionar cada árbol de Paulownia y podar con frecuencia para asegurar un tronco limpio
          para madera de alta calidad.</li>
        <li>Al inicio del cuarto año, introduciremos aves de corral en la franja. Las aves se alimentarán de lo que crece
          naturalmente y recibirán alimento suplementario. Aportarán estiércol para alimentar a los árboles. Al final del
          año cuatro,
          algunas de las aves serán cosechadas para carne. Ofrecemos los huevos de las gallinas ponedoras a nuestros
          inversores y
          clientes.</li>
        <li>En el sexto año, los árboles han crecido lo suficiente para permitir a un pequeño grupo de cerdos ibéricos
          buscar las bellotas
          de los robles (Quercus ilex). Esto sucede una vez al año y está estrictamente controlado para limitar cualquier
          daño a los árboles. Los cerdos adelgazan un poco el bosque, lo cual es útil para el crecimiento de otras plantas
          de forraje - para
          las aves - ya que ahora más luz solar puede llegar al suelo.</li>
      </ul>

      <h2>Pago al Inversor</h2>
      <p><strong>Después de la cosecha de árboles en el año doce, también pagaremos a nuestros inversores.</strong> Como
        las franjas individuales
        son bastante pequeñas, se cosecharán varias al mismo tiempo de manera sensata.</p>
      <p>Dependiendo de la oferta de inversión que elijas, recibirás beneficios adicionales mientras el bosque crece y los
        árboles de Paulownia se preparan para la cosecha de madera.</p>

      <h2>Silvopastura</h2>
      <p>Una vez que las franjas forestales tengan árboles y arbustos bien establecidos,...y estén creciendo muchas otras
        plantas de forraje, podemos dejar
        a los animales pastando en este nuevo sistema de silvopastura hasta que los árboles de Paulownia estén listos para
        la cosecha. Esperamos que esto sea
        así para el año doce. Para entonces, los árboles de Paulownia tendrán diez años de edad.</p>

      <p>A partir del cuarto año, empezaremos a utilizar el concepto de silvopastura para producir algo en el nuevo bosque
        y
        también para mantenerlo y alimentar a los árboles. Aquí te presentamos algunas de las actividades de silvopastura
        y los animales:</p>

      <dl>
        <dt>Pollo</dt>
        <dd>Las gallinas ponedoras y los pollos jóvenes (broilers) pueden buscar comida entre los árboles y están
          protegidos por el dosel formado por
          los árboles. Recolectamos los huevos y podemos llevarnos los pollos jóvenes antes de que empiecen a pelear por
          las gallinas. Para consumo humano,
          tenemos huevos y carne de pollo.</dd>

        <dt>Gansos</dt>
        <dd>Los gansos son excelentes animales para fertilizar un área. Ponen huevos (aproximadamente tres veces el tamaño
          de un huevo de gallina y
          utilizable de la misma manera en la cocina) y también pueden ser cosechados para carne.</dd>

        <dd>Los gansos también pueden servir como animales guardianes para los pollos, pero solo si hay un ganso por
          pequeño rebaño de pollos.
          Delimitamos un área con cercado eléctrico y colocamos en su interior un rebaño de pollos con un ganso guardián
          por un corto tiempo.</dd>

        <dt>Pavo</dt>
        <dd>Similar a otras aves, los pavos aportarán fertilizante y pueden ser cosechados para carne.</dd>

        <dt>Cerdo Ibérico</dt>
        <dd>El cerdo es un animal de bosque y es genial para crear una gran perturbación, lo cual es bueno para el suelo.
        </dd>
        <dd>Tenemos alrededor de 40 robles (Quercus ilex) por hectárea que producen deliciosas bellotas que los cerdos
          comen para engordar
          entre noviembre y marzo. Estas bellotas dan al jamón ibérico su sabor especial a nuez. Esperamos que nuestros
          árboles existentes produzcan más y mayores bellotas debido a la constante mejora de las condiciones del suelo y
          la disponibilidad de
          humedad.</dd>
        <dd>Debido a su costumbre de remover el suelo, administraremos la cosecha de bellotas por los cerdos de tal manera
          que se trasladarán
          a otra área después de haber comido todas las bellotas y antes de que empiecen a remover el suelo. De esta
          manera,
          protegemos los árboles de Paulownia. Sin embargo, un cierto nivel de remoción y eliminación de especies nativas
          de árboles y arbustos es positivo.
          Las plantas más débiles se eliminan, y más luz solar llega al suelo y por lo tanto más forraje para aves estará
          disponible.
        </dd>
      </dl>
    </div>
    <div v-else>
      <h2>Forest Strips</h2>
      <p>Based on several factors, we will plant the new forest in several phases and in the form of strips, each 2000 m2
        in
        size. These strips are the base unit for your investment.</p>
      <p>Each strip goes through several phases in the following order:</p>
      <ul class="phases">
        <li>Mechanically loosen up the soil and enrich it with a manure/compost mix.</li>
        <li>Install irrigation equipment.</li>
        <li>Seed Sunn Hemp (Crotalaria juncea), sunflower, and corn for biomass every three months. Over winter, we use
          rye.
        </li>
        <li>Every three months, either cut down or graze Sunn Hemp, sunflowers, and corn to add the biomass to the soil.
        </li>
        <li>At the beginning of year two, plant approximately 80 Paulownia trees and continue with the plant mix for
          biomass.</li>
        <li>At the end of year two, after cutting the biomass plants, plant native trees and shrub species to fill the
          void
          between the Paulownia trees. Perform a technical cut on the Paulownia trees for better growth. Turn the cuttings
          into wood chips and apply them around the base of each tree.</li>
        <li>During year three, inspect each Paulownia tree and prune frequently to ensure a clean trunk for high-quality
          wood.</li>
        <li>At the beginning of year four, we will introduce poultry into the strip. The birds will feed on what grows
          naturally and will receive supplemental feed. They will add manure to feed the trees. At the end of year four,
          some of the birds will be harvested for meat. We offer the eggs from the laying hens to our investors and
          customers.</li>
        <li>By year six, the trees have grown strong enough to allow a small group of Iberian pigs to hunt for the acorns
          from the oak trees (Quercus ilex). This will happen once per year and is tightly controlled in order to limit
          any
          damage to the trees. The pigs will thin the forest a bit, which is helpful for growing other forage plants - for
          the poultry - as more sunlight can now reach the ground.</li>
      </ul>

      <h2>Investor Payout</h2>
      <p><strong>After the tree harvest in year twelve, we will then also pay our investors.</strong> As the individual
        strips are rather small, several will be harvested at the same time in a sensible way.</p>
      <p>Depending on which investment offer you select, you will receive additional benefits while the forest grows and
        the
        Paulownia trees are getting ready for the wood harvest.</p>

      <h2>Silvopasture</h2>
      <p>Once the forest strips have well-established trees and shrubs,...and a lot of other forage is growing, we can let
        animals forage in this new silvopasture system until the Paulownia trees are ready for harvest. We expect this to
        be
        the case by year twelve. The Paulownia trees will then be ten years old.</p>

      <p>From year four onwards, we will start using the concept of silvopasture to produce something in the new forest
        and
        also to maintain it and feed the trees. Here are some of the silvopasture activities and animals:</p>

      <dl>
        <dt>Chicken</dt>
        <dd>Laying hens and young roosters (broilers) can forage between the trees and are protected by the canopy formed
          by
          the trees. We collect the eggs and can take out the young roosters before they begin to fight over hens. For
          human
          consumption, we have eggs and chicken meat.</dd>

        <dt>Geese</dt>
        <dd>Geese are excellent animals to fertilize an area. They lay eggs (about three times the size of a chicken egg
          and
          usable in the same way in the kitchen) and can also be harvested for meat.</dd>

        <dd>Geese can also serve as guard animals for chickens, but only if there is one goose per small flock of
          chickens.
          We fence off an area with electric netting and place inside a flock of chickens with one guard goose for a short
          while.</dd>

        <dt>Turkey</dt>
        <dd>Similar to other birds, turkeys will add fertilizer and can be harvested for meat.</dd>

        <dt>Iberian Pig</dt>
        <dd>The pig is a forest animal and is great at creating major disturbance, which is good for the soil.</dd>
        <dd>We have about 40 oak trees (Quercus ilex) per hectare that produce delicious acorns which the pigs eat to
          fatten
          themselves between November and March. These acorns give the Iberico ham its special nutty flavor. We expect our
          existing trees to produce more and larger acorns due to the ever-improving soil condition and availability of
          moisture.</dd>
        <dd>Due to their habit of uprooting soil, we will manage the acorn harvest by the pigs in such a way that they
          will
          move on to another area after they have eaten all the acorns and before they begin to uproot the soil. This way,
          we protect the Paulownia trees. However, some uprooting and removal of native tree and shrub species is
          positive.
          Weaker plants are removed, and more sunlight gets to the soil and so more forage for poultry will be available.
        </dd>
      </dl>
    </div>
  </div>
</template>

<style>
P.claim {
  font-weight: bold;
}

UL.phases LI {
  line-height: 1.5em;
}
</style>

<script>
import PurchaseTreeInvestment from './PurchaseTreeInvestment.vue'
import InvestorLogin from './InvestorLogin.vue'
import { getLanguage } from '@/library.js'

export default {
  name: 'Investors',
  components: {
    PurchaseTreeInvestment,
    InvestorLogin
  },

  methods: {
    languageCode (code) {
      return code === getLanguage()
    },

  }
}
</script>
