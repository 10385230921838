<template>
  <div class="investment-box">
    <div class="options">
      <div class="option-box">
        <h3>Dehesa Membership</h3>
        <p>Invest into building a strip of forest in a new Dehesa</p>
        <p>Price: x € yearly over 3 years</p>
        <p>Return: x € after 10 years</p>
        <h3>Membership pricing for:</h3>
        <ul>
          <li>Ham from our Iberian pigs</li>
          <li>Blue eggs</li>
          <li>Chicken meat</li>
          <li>Turkey or goose in December</li>
        </ul>
        <p class="disclaimer">Benefits become available as the forest develops.</p>
        <button class="purchase">Purchase</button>
      </div>
      <div class="option-box recommended">
        <h3>Dehesa Supporter</h3>
        <p>Invest into building a strip of forest in a new Dehesa</p>
        <p>Price: x € monthly</p>
        <p>Return: x € after 10 years</p>
        <h3>Additional Benefits and Returns:</h3>
        <ul>
          <li>X envelopes of sliced ham from our Iberian pigs</li>
          <li>Dozen blue eggs monthly</li>
          <li>Chicken meat</li>
          <li>One Turkey or goose in December</li>
        </ul>
        <p class="disclaimer">Benefits become available as the forest develops.</p>
        <button class="purchase">Purchase</button>
      </div>
      <div class="option-box">
        <h3>Dehesa Investor</h3>
        <p>Invest into building a strip of forest in a new Dehesa</p>
        <p>Price: x €</p>
        <p>Return: x € after 10 years</p>
        <h3>Additional Benefits:</h3>
        <ul>
          <li>Once per year a leg of ham or hand cut into slices from our Iberian pigs</li>
          <li>Dozen blue eggs monthly</li>
          <li>One Turkey or goose in December</li>
        </ul>
        <p class="disclaimer">Benefits become available as the forest develops.</p>
        <button class="purchase">Purchase</button>
      </div>
    </div>
  </div>
</template>

<style>
DIV.investment-box {
  margin-top: 3em;
  margin-bottom: 3em;
}

DIV.options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
}

DIV.option-box {
  border: 1px solid black;
  border-radius: 10px;
  padding: 20px;
  width: 25%;
}

DIV.option-box.recommended {
  border-width: 2px;
  margin: -20px;
}

P.disclaimer {
  font-size: 80%;
}
</style>
